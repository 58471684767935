
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCurrency } from '@/misc/getCurrency';

@Component({
  name: 'FeeSelector',
})
export default class extends Vue {
  @Prop() fee: number | undefined;
  @Prop() fees: Array<any> | undefined;
  feeDisplayed: number | undefined = 0;

  mounted() {
    const currency = getCurrency();
    const feeDisplayed = this.fees?.find((fee: any) => currency === fee.currency);
    if (feeDisplayed) {
      this.feeDisplayed = feeDisplayed.amount;
    }
  }

  get currency() {
    return getCurrency();
  }

  set currency(item: any) {
    this.$emit('changeCurrency', item.currency);
  }
}
