
import { clone } from 'ramda';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Meta } from '@/misc/decorators';
import { loadStripe } from '@stripe/stripe-js';
import { Auth } from '@/store/modules';
import RecentReviews from '@/components/RecentReviews.vue';
import ClassDetails from '@/components/ClassDetails.vue';
import FeeSelector from '@/components/FeeSelector.vue';
import { countryCodes } from '@/misc/countryCodes';
import { User } from '../store/auth';
import { isMaterialsKitEnabled } from '@/misc/featureFlags';
import { getCurrency } from '@/misc/getCurrency';
import router from '@/router';



@Component({
  name: 'ClassView',
  components: {
    RecentReviews,
    ClassDetails,
    FeeSelector,
  }
})
export default class extends Vue {
  isLoading = true;
  error = '';
  model: any = {};
  loadingReviews = false;
  meetings: any = [];
  requirements: string[] = [];
  materials: string[] = [];
  canBook = false;
  addReviewDialog = false;
  fixedBookNowButtonVisible = false;
  tab = 'description';
  upcoming: any[] = [];
  categories: any[] = [];
  category = { id: '', name: '' };
  subcategory = { id: '', name: '' };
  id: string = '';
  materialsAllowedCountries: any[] = [];
  keyClassDetails: number = 0;
  featureFlagMaterialsKit: boolean = true;
  feeSelectorKey: number = 0;
  isFeeWithMaterials: boolean = true;
  fee: any = undefined;
  fees: any = undefined;




  @Meta
  metaInfo() {
    return {
      title: this.model.name && this.model.teacher.name ? `${this.model.name} by ${this.model.teacher.name} | SkillToon` : 'Class by Teacher | SkillToon',
      link: [
        {
          rel: 'canonical',
          href: `https://skilltoon.com/classes/${this.slug}`,
        },
      ],
      meta: [
        {
          property: 'og:url',
          content: `https://skilltoon.com/classes/${this.slug}`,
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:title',
          content: this.model.name && this.model.teacher.name ? `${this.model.name} by ${this.model.teacher.name} | SkillToon` : 'Class by Teacher | SkillToon',
        },
        {
          property: 'og:description',
          content: this.model.name && this.model.teacher.name ? `Take ${this.model.name} by ${this.model.teacher.name} at home and expand your skills.` : 'Take Class by Teacher at home and expand your skills.',
        },
        {
          property: 'og:image',
          content: 'https://skilltoon-uploads-dev.s3.amazonaws.com/8837396b-783b-4ab8-8337-24cd6303a2af/classes-64865c5a-c39d-47db-bd5f-45b878c0bfa2-1619531198393.jpg?q=1619531484774',
        },
        {
          name: 'description',
          content: this.model.name && this.model.teacher.name ? `Take ${this.model.name} by ${this.model.teacher.name} at home and expand your skills.` : 'Take Class by Teacher at home and expand your skills.',
        },
        { name: 'keywords', content: this.model.name && this.model.teacher.name ? `${this.model.name}, classes by ${this.model.teacher.name}` : 'Class, classes by Teacher' },
        { name: 'robots', content: 'index,follow' },
        { property: 'twitter:card', content: 'summary_large_image' },
        {
          property: 'twitter:url',
          content: `https://skilltoon.com/classes/${this.slug}`,
        },
        {
          property: 'twitter:title',
          content: this.model.name && this.model.teacher.name ? `${this.model.name} by ${this.model.teacher.name} | SkillToon` : 'Class by Teacher | SkillToon',
        },
        {
          property: 'twitter:description',
          content: this.model.name && this.model.teacher.name ? `Take ${this.model.name} by ${this.model.teacher.name} at home and expand your skills.` : 'Take Class by Teacher at home and expand your skills.',
        },
        {
          property: 'twitter:image',
          content: 'https://skilltoon-uploads-dev.s3.amazonaws.com/8837396b-783b-4ab8-8337-24cd6303a2af/classes-64865c5a-c39d-47db-bd5f-45b878c0bfa2-1619531198393.jpg?q=1619531484774',
        },
      ],
    };
  }

  @Watch('$route.params.slug')
  onClassChanged() {
    this.refresh();
  }


  get currency() {
    return getCurrency();
  }

  set currency(currency: string) {
    this.keyClassDetails++;
    localStorage.setItem('skilltoon-currency', currency);
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get classId() {
    return this.model.id;
  }

  get slug() {
    return this.$route.params.slug;
  }

  get closestMeeting() {
    if (!this.model.meetings.length) {
      return '--';
    }

    const closest = this.model.meetings.reduce((a: any, b: any) => {
      const firstDate = new Date(a.date);
      const secondDate = new Date(b.date);
      return firstDate.getTime() > secondDate.getTime() ? a : b;
    });
    return closest;
  }

  get user() {
    return Auth.user as User;
  }

  get isOwnClass() {
    if (!Auth.loggedIn) {
      return false;
    }

    return this.model.teacherId === this.user.teacherId;
  }

  get breadcrumbs() {
    const ret: any[] = [
      { text: 'Home', to: '/' },
      { text: 'Classes', to: '/categories' },
    ];

    ret.push({
      text: this.category.name,
      to: `/categories/${this.category.id}`,
    });

    if (this.subcategory.id) {
      ret.push({
        text: this.subcategory.name,
        to: `/categories/${this.subcategory.id}`,
      });
    }

    return ret;
  }

  changeFeeType(val: boolean) {
    if (val) {
      this.fee = this.model.feeWithMaterials;
      this.fees = this.model.feesWithMaterials;
    } else {
      this.fee = this.model.fee;
      this.fees = this.model.fees;
    }


    this.isFeeWithMaterials = val;
    this.feeSelectorKey++;
  }

  upcomingSort() {
    this.upcoming.forEach((el) => {
      el.meetings.sort((a: any, b: any) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    })
    this.upcoming.sort((a: any, b: any) => new Date(a.meetings[0].date).valueOf() - new Date(b.meetings[0].date).valueOf())
  }

  hasMeetingEnded(meeting: any) {
    return new Date(meeting.date).valueOf() < Date.now();
  }

  goToSection(id: string) {
    this.tab = id;
    this.$vuetify.goTo(`#${id}`);
  }

  async mounted() {
    
    this.refresh();
    this.setupViewportListener();
    this.featureFlagMaterialsKit = isMaterialsKitEnabled();
  }

  beforeDestroy() {
    this.unloadViewportListener();
  }

  async refresh() {
    this.isLoading = true;
    try {
      const response = await Vue.$axios.get(`/classes/${this.slug}`);
      this.model = clone(response.data);
      this.meetings = this.model.meetings.sort(
        (a: any, b: any) =>
          new Date(a.date).valueOf() - new Date(b.date).valueOf()
      );

      if (this.model.details.hasRequirements) {
        this.requirements = this.model.details.requirements.split('\n');
      }

      if (this.model.details.materials) {
        this.materials = this.model.details.materials.split('\n');
      }

      if (this.model.materialsAllowedCountries && Object.keys(this.model.materialsAllowedCountries).length !== 0) {
        this.materialsAllowedCountries = this.model.materialsAllowedCountries.map((item: any) => {
          return countryCodes[item];
        });
      }

      const anyMeetingsPassed = this.meetings.some((meeting: any) =>
        this.hasMeetingEnded(meeting)
      );

      if (!anyMeetingsPassed && !this.isOwnClass) {
        if (Auth.loggedIn) {
          const seatRes = await Vue.$axios.get(
            `/classes/${this.classId}/check-reservation`
          );
          if (seatRes.data && seatRes.data.id) {
            this.canBook = false;
          } else {
            this.canBook = true;
          }
        } else {
          this.canBook = true;
        }
      } else {
        this.canBook = false;
      }
      const uriEncodedClassName = encodeURI(this.model.name);

      const upcomingClasses = await Vue.$axios.get(
        `/classes?teacherId=${this.model.teacherId}&exactName=${uriEncodedClassName}&upcoming=true`
      );
      this.upcoming = clone(upcomingClasses.data.items).filter(
        (course: any) => course.id !== this.model.id
      );
      this.$forceUpdate();

      const categoryRes = await Vue.$axios.get(
        `/categories/${this.model.categoryId}`
      );
      if (categoryRes.data.path[0]) {
        const categoryRes2 = await Vue.$axios.get(
          `/categories/${categoryRes.data.path[0]}`
        );
        this.category = categoryRes2.data;
        this.subcategory = categoryRes.data;
      } else {
        this.category = categoryRes.data;
        this.subcategory = { id: '', name: '' };
      }

      const book = this.$route.query.book;
      const currency = this.$route.query.currency;
      if (book && typeof book === 'string') {
        if (currency && typeof currency === 'string') {
          this.reserveSeat(book, currency);
        }
        else {
          this.reserveSeat(book);
        }
      }
    } catch (err: any) {
      this.error = err.message;
    }
    this.isLoading = false;
    this.changeFeeType(this.isFeeWithMaterials);
    this.upcomingSort();
  }

  async changeCurrency(currency: string) {
    this.currency = currency ?? 'USD';
    this.feeSelectorKey++;
  }

  async reserveSeat(classId: string, currency?: string, withMaterials?: boolean) {
    if (!this.loggedIn) {
      const isQuery = window.location.href.includes('?') ? '&' : '?';
      this.$router.push(
        `/login?redirectUrl=${window.location.href}${isQuery}book=${classId}%26currency=${currency}&authError=true`
      );
      return;
    }

    if (!currency) {
      currency = getCurrency();
    }


    if (withMaterials && this.materials.length)
      return this.$router.push('/classes/shipping-adreess/' + this.slug);

    const response = await Vue.$axios.post(
      `/classes/${classId}/make-reservation/${currency}`
    );
    const { sessionId } = response.data;
    if (!sessionId) {
      this.$router.push('/reservation-confirmation');
      return;
    }

    if (currency === "INR") {

      return window.location.href = sessionId;
    } else {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY as string);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { error } = await stripe!.redirectToCheckout({
        sessionId,
      });


      if (error) {
        throw error;
      }
    }
  }


  isMobile(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  }

  onMobileScroll() {
    const topClassDetails = document.querySelector('#top-class-details');
    const bottomClassDetails = document.querySelector('#bottom-class-details');

    if (!topClassDetails || !bottomClassDetails) return;

    const topClassDetailsBottomPosition = topClassDetails.getBoundingClientRect().bottom;
    const bottomClassDetailsTopPosition = bottomClassDetails.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    const shouldBeVisible = (topClassDetailsBottomPosition - 60 < 0) && (bottomClassDetailsTopPosition - windowHeight > 0);
    this.fixedBookNowButtonVisible = shouldBeVisible;
  }

  setupViewportListener() {
    if (!this.isMobile()) return;
    window.addEventListener('scroll', this.onMobileScroll);
  }

  unloadViewportListener() {
    if (!this.isMobile()) return;
    window.removeEventListener('scroll', this.onMobileScroll);
  }
}
