
import { Component, Vue } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Auth } from '@/store/modules';
import MeetingRow from '@/components/MeetingRow.vue';
import ClassRow from '@/components/ClassRow.vue';
import { getCurrency } from '@/misc/getCurrency';
import { loadStripe } from '@stripe/stripe-js';

@Component({
  components: {
    MeetingRow,
    ClassRow,
  },
})
export default class Profile extends Vue {
  isLoading = true;
  class: any = {}
  materialsShippingAddress: any = {
    name: "",
    mobileNumber: "",
    postalCode: "",
    building: "",
    street: "",
    city: "",
    state: "",
    country: "IN",
  };
  categories: any[] = [];
  category = { id: '', name: '' };
  subcategory = { id: '', name: '' };
  errorSave = ""

  requiredRule = (v: string) => !!v || 'This field is required';
  requiredForMaterials = (model: any) => (v: any) => (`${v}` !== '' || !model.details.hasMaterials) || 'This field is required.'
  pinCodeRule = (v: string) => /([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/.test(v) || "Please use correct pincode";
  // eslint-disable-next-line no-useless-escape
  phoneRule = (v: string) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || "Please use correct phone number";
  materialsAllowedCountriesList: any[] = [
    { text: 'India', value: 'IN', }
  ];

  get loggedIn() {
    return Auth.loggedIn;
  }


  async mounted() {
    this.refresh();
  }

  get slug() {
    return this.$route.params.slug;
  }

  get classId() {
    return this.class.id;
  }

  get currency() {
    return getCurrency();
  }

  hasMeetingEnded(meeting: any) {
    return new Date(meeting.date).valueOf() < Date.now();
  }


  save() {
    if (!(this.$refs as any).form.validate()) {
      this.errorSave = "Failed to save Class.Please verify if the data you entered above is correct."
    }
  }

   get breadcrumbs() {
    const ret: any[] = [
      { text: 'Home', to: '/' },
      { text: 'Classes', to: '/categories' },
    ];

    ret.push({
      text: this.category.name,
      to: `/categories/${this.category.id}`,
    });

    if (this.subcategory.id) {
      ret.push({
        text: this.subcategory.name,
        to: `/categories/${this.subcategory.id}`,
      });
    }

    return ret;
  }


  async refresh() {
    this.isLoading = true;
    try {
      const response = await Vue.$axios.get(`/classes/${this.slug}`);
      this.class = clone(response.data);
     
      const categoryRes = await Vue.$axios.get(
        `/categories/${this.class.categoryId}`
      );
      if (categoryRes.data.path[0]) {
        const categoryRes2 = await Vue.$axios.get(
          `/categories/${categoryRes.data.path[0]}`
        );
        this.category = categoryRes2.data;
        this.subcategory = categoryRes.data;
      } else {
        this.category = categoryRes.data;
        this.subcategory = { id: '', name: '' };
      }
    } catch (err: any) {
      this.$router.push({ name: 'categories' });
    }
    this.isLoading = false;
  }


  async reserveSeat(classId: string, currency?: string) {
    if (!this.loggedIn) {
      const isQuery = window.location.href.includes('?') ? '&' : '?';
      this.$router.push(
        `/login?redirectUrl=${window.location.href}${isQuery}book=${classId}%26currency=${currency}&authError=true`
      );
      return;
    }

    if (!currency) {
      currency = getCurrency();
    }
    
    const response = await Vue.$axios.post(
      `/classes/${classId}/make-reservation/${currency}`,
      { materialsShippingAddress: this.materialsShippingAddress }
    );
    const { sessionId } = response.data;
    if (!sessionId) {
      this.$router.push('/reservation-confirmation');
      return;
    }

    if (currency === "INR") {
      return window.location.href = sessionId;
    } else {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY as string);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { error } = await stripe!.redirectToCheckout({
        sessionId,
      });


      if (error) {
        throw error;
      }
    }
  }

}
