
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import NewMessageDialog from './NewMessageDialog.vue';
import FeeSelector from './FeeSelector.vue';
import { Auth } from '@/store/modules';
import { isMaterialsKitEnabled } from '@/misc/featureFlags';
import { getCurrency } from '@/misc/getCurrency';

@Component({ name: 'ClassDetails',
  components: { NewMessageDialog, FeeSelector }
})
export default class ClassDetails extends Vue {
  @Prop() data!: any;
  @Prop() closestMeeting!: any[];
  @Prop() canBook!: boolean;
  @Prop() canMessage!: boolean;
  @Prop({ default: false }) viewOther!: boolean;
  @Prop() isFeeWithMaterials!: boolean;
  @Prop() fee: any;
  @Prop() fees: any;

  feeSelectorKey: number = 0;
  featureFlagMaterialsKit: boolean = true;

  get loggedIn() {
    return Auth.loggedIn;
  }

  get currency() {
    return getCurrency();
  }

  async mounted() {
    this.featureFlagMaterialsKit = isMaterialsKitEnabled();
  }

  clickBook() {
    if (this.featureFlagMaterialsKit) {
      if (this.isFeeWithMaterials) {
        this.$emit('bookWithMaterials');
      }
      else {
        this.$emit('book');
      }
    } else {
      this.$emit('book');
    }
  }

  changeFeeType(val: boolean) {
    this.$emit('changeFeeType', val);
    this.$emit('changeCurrency', this.currency);
  }

  changeCurrency(currency: string) {
    this.$emit('changeCurrency', currency);
  }

  openNewMessageDialog() {
    if (!this.loggedIn) {
      const isQuery = window.location.href.includes('?') ? '&' : '?';
      this.$router.push(
        `/login?redirectUrl=${window.location.href}${isQuery}authError=true`
      );
      return;
    }

    (this.$refs.newMessage as any).state(true);
  }
}
